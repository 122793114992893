.navbar {
  background-color: rgb(255,255,255);
  position: fixed !important;
  width: 100%;
  z-index: 99;
  top: 0;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 0 45px;
}

.nav-menu {
  padding:0;
  display: flex;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
  max-height: none !important;
  overflow-y:hidden !important;
}
.nav-menu ul {
  overflow-y: hidden !important;
}

.nav-icon {
  display: none;
}
.aboutNavLink {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500 !important;
  color: #2F2F2F !important;
  padding: 20px 10px;
  letter-spacing: 1px;
}
.aboutNavLink:hover {
  color: #FDDC2E !important;
}
.downloadBtn{
border-radius: 50px;
  padding: 10px 30px;
  color: #111111 !important;
background-color:#0066CC !important;
  font-weight: bold;
  font-size:12px;
  margin-top:8x;
}
@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
    border-top: 1px solid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #2F2F2F;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    height: auto;
    padding: 10px;
  }
  .nav-item .active {
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #ffffff;
  }
}
