

.logo {
  float: left;
  width: 50% !important;
  height: 31px;
  margin: 0px 24px 16px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}


[data-theme="dark"] .site-layout .site-layout-background {
  background: #2F2F2F;
}

.restaurantTitle {
  display: flex;
  justify-content: flex-start;
  padding: 20px 20px;
  font-size: 25px;
}

.viewRestaurant {
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px;
  font-size: 16px;
}

.cartIcon {
  width: 55px;
  height: 25px;
}

.restauranrHeaderTitle {
  line-height: 9px;
  margin-left: 40px;
  margin-top: 20px;
}

.mainWrapper {
  padding: 40px;
}

.addBtn {
  border-radius: 0 0 5px 5px;
  height: 50px;
}

.cardTitle {
  padding: 10px 10px;
  font-weight: bold;
  font-size: 20px;
}

.restaurantListWrapper {
  display: flex;
  justify-content: space-around;
}

.checkoutBtn {
  background-color: #0066CC !important;
  border: none !important;
  font-size: 16px;
  font-weight: 600;
  color: #fff !important;
}

.increBtn {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.priceContainer {
  justify-content: center !important;
  display: flex !important;
}

.minus,
.plus {
  background-color: #0066CC;
  font-weight: bold;
  cursor: pointer;
  padding: 3px 10px;
  color: #fff !important;
  border-radius: 4px;
}

.btnContainer {
  background-color: #0066CC !important;
  border: none !important;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
}

.btns {
  border: none !important;
  padding: 10px !important;
}

.btnLink {
  background-color: #0066CC !important;
  color: white !important;
  padding: 8px !important;
}

.formWrapper {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.formHeader {
  background-color: #FDDC2E;
  border-radius: 6px 6px 0px 0px;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 600;
}

.saveBtn {
  background: #0066CC !important;
  border: none !important;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.btnVipps {
  background: #FF5B24 !important;
  border: none !important;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.payCard {
  padding: 10px;
}

.itemName {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

label {
  color: #686b78 !important;
  font-weight: 500;
}

.orderBtn {
  background: #0066CC !important;
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 600;
  padding: 6px 20px;
  border: none;
  border-radius: 5px;
}

.btns {
  display: flex;
  justify-content: space-around;
}

.cardFooter {
  padding: 10px;
}

.formRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

span.ant-descriptions-item-content {
  justify-content: end !important;
  text-align: right;
}

.formLeft {
  width: 620px;
}

.goBackBtn {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.formCard1 {
  width: 400px;
}

.formInfo {
  height: 550px;
}

.cardPayment {
  height: 570px;
}

.cartBadge {
  position: absolute;
  top: 8px;
  right: 0;
  font-size: 12px !important;
  background-color: #FEDE30 !important;
  color: #2F2F2F;
  border-radius: 50px;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsiveness */
@media only screen and (min-device-width: 320px) and (max-device-width: 844px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .formWrapper {
    display: block;
    justify-content: center;
  }

  .formRow {
    display: block;
  }

  .formLeft {
    height: auto;
    width: auto;
  }

  .mainWrapper {
    padding: 30px;
  }

  .restaurantListWrapper {
    display: block;
    justify-content: center;
  }

  .formInfo {
    height: 680px;
  }

  /* .formCard1 {
    margin-top: 20px;
  } */

  .formFooter {
    display: block;
    justify-content: center;
  }

  .cardFooter {
    justify-content: center;
  }
  .formWrapper {
    overflow-x: hidden;
  }

  .ant-form-item {
    margin-bottom: 5px;
  }

  .restaurantHeader {
    height: auto;
  }

  .restaurantTitleMain {
    display: block;
  }

  .ant-col-4 {
    max-width: 100% !important;
  }

  .restauranrHeaderTitle {
    margin-left: 21px;
  }

  .formWrapper {
    display: block;
    justify-content: center;
  }

  .formRow {
    display: block;
  }

  .formLeft {
    height: auto;
    width: auto;
  }

  .mainWrapper {
    padding: 30px;
  }

  .restaurantListWrapper {
    display: block;
    justify-content: center;
  }

  .site-card-border-less-wrapper {
    padding-left: 0px;
    margin-top: 20px;
  }

  .formInfo {
    height: 680px;
  }

  /* .formCard1 {
    width: 360px;
    margin-top: 20px;
  } */

  .formFooter {
    display: block;
    justify-content: center;
  }

  .cardFooter {
    justify-content: center;
  }

  .formWrapper {
    overflow-x: hidden;
  }


  .ant-form-item {
    margin-bottom: 5px;
  }

  .restaurantHeader {
    height: auto;
  }

  .restaurantTitleMain {
    display: block;
  }

  .ant-col-4 {
    max-width: 100% !important;
  }

  .restauranrHeaderTitle {
    margin-left: 21px;
  }

  .formWrapper {
    display: block;
    justify-content: center;
  }

  .formRow {
    display: block;
  }

  .formLeft {
    height: auto;
    width: auto;
  }

  .saveBtn {
    font-size: 10px;
  }

  .site-card-border-less-wrapper {
    padding-left: 0px !important;
  }

  .formWrapper {
    display: block;
    justify-content: center;
  }

  .formRow {
    display: block;
  }

  .formLeft {
    height: auto;
    width: auto;
  }

  /* .cardSize {
    margin-bottom: 10px;
  } */

  .formWrapper {
    overflow-x: hidden;
  }

  .formWrapper {
    display: block;
    justify-content: center;
  }

  .formRow {
    display: block;
  }

  .formLeft {
    height: auto;
    width: auto;
  }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .formWrapper {
    display: block;
    justify-content: center;
  }

  .formRow {
    display: block;
  }

  .formLeft {
    height: auto;
    width: auto;
  }
}
