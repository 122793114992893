.css-ece9u5 {
    min-width: 350px !important;
    margin-left: 0px !important;
}
.MuiFormControlLabel-root{
    display:flex !important;
    text-align:left !important;
}
.MuiTypography-h6 {
  color: #2F2F2F !important;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width:98% !important;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 667px) and (orientation : portrait) {
    .css-ece9u5{
        min-width: 150px !important;
    }
}
@media only screen and (min-device-width : 550px) and (max-device-width : 1200px){
.css-1wnsr1i {
    overflow-y: scroll !important;
    height: 100% !important;
    transition: all 0.9s;
}
      
    .css-1wnsr1i::-webkit-scrollbar {
        -webkit-appearance: none;
        transition: all 0.9s;
      }

      .css-1wnsr1i::-webkit-scrollbar:vertical {
        width: 7px;
      }
      .css-1wnsr1i::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white;
        background-color: rgba(0, 0, 0, 0.2);
        transition: all 0.9s;
      }
}

@media only screen and (min-device-width : 1080px) and (max-device-width : 1920px){
  .css-1wnsr1i {
    overflow-y: scroll !important;
    height: 600px !important;
    transition: all 0.9s;
    width: 750px !important;
  }
  .css-1wnsr1i::-webkit-scrollbar {
    -webkit-appearance: none;
    transition: all 0.9s;
  }
  
  .css-1wnsr1i::-webkit-scrollbar:vertical {
    width: 7px;
  }
  
  .css-1wnsr1i::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.2);
    transition: all 0.9s;
  }
}
    #boxSupport {
      display: flex;
      flex-direction: column;
      height: 100vh;
      padding: 20px;
      border-radius: 10px;
      background: white;
      align-items: center;
      justify-content: center;
  }
.supportLogo{
  width: 100px !important;
}
.css-zcbmsk-MuiButtonBase-root-MuiButton-root{
  background-color: #2F2F2F !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root{
  background-color: #0066CC !important;
}
.css-1wnsr1i{
  width: 700px !important;
}