.boxContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 70%;
    background-color: #fff;
    border: 1px solid #888;
    box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
    padding: 32px;
    background: rgb(8, 166, 69);
    color: white;
    border-radius: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-around;
    -ms-flex-pack: space-around;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.img1{
    width:200px;
}
.alignContent .img2{
    width:200px;
    margin-left: 30px !important;
    margin-top: 76px !important;
}
.columnRight{
    display:block;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 667px) and (orientation : portrait){
    .boxContainer{
        display: block !important;
    }
    .alignContent{
        text-align:center;
    }
    .img1{
        width:130px;
    }
    .columnRight{
        text-align: center !important;
    }
    .boxContainer{
        display: block !important;
    }
    .alignContent{
        text-align:center;
    }
    .columnRight{
        text-align: center !important;
    }
    .img2{
       display:none;
    }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait){
    .boxContainer{
        display: block !important;
    }
    .alignContent{
        text-align:center;
    }
    .columnRight{
        text-align: center !important;
    }
    .img2{
       display:none;
    }
}