@import "~antd/dist/antd.css";
@font-face {
  font-family: 'Sansita';
  src: url('../src/assets/fonts/SansitaOne.ttf');
  font-weight: normal; 
  font-style: normal; 
}
/*------------ Scroll styling start ------------*/
ul {
  overflow-y: scroll;
}

ul::-webkit-scrollbar {
  -webkit-appearance: none;
  transition: all 0.9s;
}

ul::-webkit-scrollbar:vertical,
::-webkit-scrollbar {
  width: 5px;
}

ul::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid white;
  transition: all 0.9s;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #DCDCDC;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #E0E0E0;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #0066CC;
}

/*------------ Scroll styling end ------------*/

/* common css of full code start */
body {
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: #2F2F2F !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif !important;
  font-weight: 800 !important;
  font-style: normal;
}

h1,
h1 span,
h2 span,
h2 {
  font-size: 45px !important;
  font-weight: 900 !important;
}

.overflow-y {
  overflow-y: scroll;
}

.bottom-0 {
  bottom: 0 !important;
}

.positionRelative {
  position: relative !important;
}

.positionAbsolute {
  position: absolute !important;
}

.textUnderline {
  text-decoration: underline !important;
}

.textItalic {
  font-style: italic;
}

.font-bold {
  font-weight: bold !important;
  ;
}

.font-400 {
  font-weight: 400 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.font-900 {
  font-weight: 900 !important;
}

.positionFixed {
  position: fixed !important;
}

.zIndex-99 {
  z-index: 99;
}

.giftCardFormBg {
  height: 100vh;
  background: url("./assets/mobile/giftCardFormbg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.seeDescButton {
  box-shadow: none !important;
  text-decoration-line: underline !important;
}

.bgColorWhite {
  background-color: #fff !important;
  background: #fff !important;
}
.greenAccentText{
  color: #11d187 !important;
  font-size: 10px !important;
  font-weight: 600;
}
.giftCardBox  p{
  font-size: 10px !important;
}
.greenAccentBg {
  background-color: #11d187 !important;
  letter-spacing: 1px;
  transition: 0.2s ease-in-out;
}

.mainBgColor,
.ant-btn-primary {
  background-color: #0066CC !important;
}

.mainFooterBg {
  height: 100vh;
  background:url("./assets/footer-web.webp");
  background-size: cover;
}

.errorMessage,
.colorRed {
  color: #D00000 !important;
}

.shortCutStyle .ant-space.ant-space-horizontal.ant-space-align-center{
 width: 100%;
 justify-content: space-between;
}

.capitalize-first-word {
  text-transform: lowercase;
}

.capitalize-first-word::first-letter {
  text-transform: capitalize;
}

.border-none {
  border: none !important;
}

.height-auto {
  height: auto;
}

.textBlack {
  color: #2F2F2F !important;
}

.textWhite,.profileBox 


.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-14 {
  font-size: 14px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-35{
  font-size: 35px !important;
}
.font-55{
  font-size: 55px !important;
}.font-10{
  font-size: 10px;
}
.font-12,
.subTitle .redClosedTag,
.subTitleTag p,
.subTitle .ant-tag span,.ant-divider-horizontal.ant-divider-with-text{
  font-size: 12px !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.width-100,
.ant-carousel {
  width: 100% !important;
}

.height-100,
.ant-card-body {
  height: 100% !important;
}

.height-80 {
  height: 80vh !important;
}

.border-radius-100 {
  border-radius: 100px !important;
}

.border-radius-50 {
  border-radius: 50px !important;
}

.border-radius-10 {
  border-radius: 10px !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.spreadedShadow,
.col1,
.subCatListUL,
.ant-card {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px !important;
}

.commonShadowAndRound,
.ant-modal-content {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 14px !important;
}

.font65px {
  font-size: 65px !important;
}

.padding-40-180 {
  padding: 80px 180px;
}

.text-uppercase {
  text-transform: uppercase;
}

.sansita-black {
  font-family: "Sansita", serif !important;
  font-weight: 900 !important;
  font-style: italic !important;
}

.all-flex-center, .htmlAdv .ant-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-container {
  display: flex;
  flex-wrap: wrap;
}

.column-container {
  display: flex;
  align-items: stretch;
}

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* common css of full code end */

/* map code start */
.mapContainer {
  height: 50vh;
}

.search-location {
  justify-content: end;
}

.suggestions {
  padding: 0;
}
.vh90andScroll{
  background: url("./assets/mobileBg.webp");
  background-size: cover;
  width: 100%;
  height: 92vh !important;
  overflow: hidden scroll !important;
  margin-bottom: 20px;
}

ul.suggestions {
  height: 30vh;
  overflow-y: scroll !important;
}

.mapboxgl-ctrl-geocoder--suggestion-address,
.mapboxgl-ctrl-geocoder--suggestion-title {
  color: #2F2F2F !important;
  font-size: 12px;
  line-height: 19px;
}

.suggestions-wrapper li {
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon-loading,
.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right>*,
.mapboxgl-ctrl-geocoder--icon-search,
.mapboxgl-ctrl-geocoder--powered-by {
  display: none !important;
}

.mapboxgl-ctrl-geocoder {
  width: 190px !important;
}

.mapboxgl-user-location-accuracy-circle {
  height: 250px !important;
  width: 250px !important;
}

.mapboxgl-ctrl-geocoder {
  font-size: 12px;
  line-height: 24px;
  position: relative;
  min-width: 240px;
  z-index: 1;
  border-radius: 4px;
  transition: width .25s, min-width .25s;
}

.mapboxgl-ctrl-geocoder--input {
  font: 12px;
  border: 0;
  width: 100%;
  border: 1px solid;
  color: #404040;
  color: rgba(0, 0, 0, 0.75);
  padding: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.geocoder {
  z-index: 1;
  left: 50%;
  margin-left: -25%;
  top: 10px;
}

.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right>* {
  z-index: 2;
  right: 8px;
  top: 7px;
}

.mapboxgl-ctrl-geocoder .suggestions {
  border-radius: 4px;
  left: 0;
  list-style: none;
  top: 110%;
  top: calc(100% + 6px);
  z-index: 1000;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right>* {
  z-index: 2;
  right: 8px;
  top: 7px;
}

.mapboxgl-ctrl-geocoder--button {
  line-height: 1;
}

.mapboxgl-ctrl-geocoder--icon-search {
  top: 13px;
  left: 12px;
  width: 23px;
  height: 23px;
}

.geocoder,
.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right>*,
.mapboxgl-ctrl-geocoder .suggestions,
.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right>*,
.mapboxgl-ctrl-geocoder--icon-search {
  position: absolute;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-top-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end !important;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder .suggestions,
.mapboxgl-ctrl-geocoder--button {
  background-color: #fff;
}

/* map code end */
#aBoardWebPage .ant-card-cover{
  display: flex;
  align-items: center;
  justify-content: center;
}
.parent {
  display: flex;
  height: 100%;
}
.giftCardBg  .ant-card-meta-title{
  font-size: 13px !important;
}
.col1,
.subCat,
.productCol {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.modal-bill .ant-modal-footer, ul.slick-dots.slick-dots-bottom{
  display: none !important;
}

.modal-bill .ant-modal-body,
.cardContainer .ant-card-body,
.cardSize .ant-card-body,
.eMarketCard .ant-card-body,
#tflMarketRow .ant-card-body,.modalHistory .modal-footer,
.giftCardBg .ant-card-body {
  padding: 0 !important;
}
.productDisplay-card .ant-modal-body{
  height: 50vh;
  overflow: hidden scroll;
}

.productDisplay-card .ant-modal-body,
.productDisplay-card .productModal,
.modal-bill .bill-body,.giftCardBox .ant-card-body,.historyCard .ant-card-body, .shortCutStyle .ant-card-body,.logBox, .tflMarketProCard .ant-card-body, .htmlAdv .ant-card-body, #arkivAboard .ant-card-body, .giftCardFormBg  .ant-card-body, .tflGiftPAge .ant-card-body, #profileInABoard
.ant-card-body {
  padding: 15px !important;
}
#profileInABoard .ant-card{
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid;
}
#profileInABoard .ant-card-bordered .ant-card-cover, #arkivAboard .ant-card-body{
  border-top: 1px solid;
}
.ServiceCardWeb .service-card {
  height: 300px;  
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  text-align: center; 
  padding: 16px;
  box-sizing: border-box;
}

.ServiceCardWeb .service-card img {
  max-height: 100px; 
  object-fit: contain; 
}

.ServiceCardWeb .service-card h2 {
  font-size: 18px !important;
  margin: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}

.ServiceCardWeb .service-card p {
  flex-grow: 1; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  margin: 8px 0;
}

.ServiceCardWeb .service-card .ant-btn {
  margin-top: auto;
  width: 100%; 
}

.col1 {
  overflow-y: scroll;
}
.digitalServicePage #tflMarketRow-panel-item-1 .ant-row.justify-content-between, .digitalServicePage #tflMarketRow-panel-item-2 .ant-row.justify-content-between{
  justify-content: start !important;
}


.digitalServicePage .ant-tabs-nav , .orderHistory .ant-tabs-nav {
  position: fixed;
  top: 70px;
  right: 0;
  left: 30px;
}

.digitalServicePage .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .orderHistory .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #FDDC2E !important;
}
.digitalServicePage .ant-tabs-tab-btn, .orderHistory .ant-tabs-tab-btn {
  color: #000;
}
#arkivAboard{
  background-position: center !important;
  background: url("./assets/mobile/arkivBg.webp");
  height: 92vh;
  overflow: hidden scroll;
  background-size: cover;
}
 #arkivAboard .ant-card-cover {
display: flex;
align-items: center;
justify-content: center;
padding: 20px;
}
.shortCutStyle{
  height: 100vh !important;
  padding-bottom: 60px !important;
  margin-bottom: 20px;
  overflow: hidden scroll !important;
}
.bannerImg {
  background: url("../src/assets/bg.webp") !important;
  height: 100vh !important;
  display: flex;
  background-size: cover !important;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat !important;
}

.bannerImg .bannerBtn {
  background-color: #FDDC2E !important;
  font-size: 16px !important;
  font-weight: 600;
  padding: 10px 80px;
  margin-top: 0 !important;
}
.underConstructionImg{
  background: url("../src/assets/bg.webp") !important;
  height: 100vh !important;
  display: flex;
  background-size: cover !important;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat !important;
}

.mobileCardRest {
  cursor: pointer;
}

.mobileCardRest .restCard .ant-card-body {
  padding: 0 !important;
}

.commonShadowAndRound .ant-modal-title,
.commonShadowAndRound .ant-modal-close,
.btn-close,
button.btn-close {
  color: white !important;
}

.commonShadowAndRound .ant-modal-close:hover {
  color: #ccc;
}

.increaseBtnBOrders {
  border: 1px solid #d9d9d9;
  border-radius: 24px;
  width: 100%;
  padding: 0px;
  text-align: center;
}

.commonShadowAndRound .ant-modal-header {
  border-radius: 14px 14px 0 0;
  background-color: #0066CC;
}

.commonShadowAndRound .ant-modal-footer {
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.25);
  border-radius: 0 0 14px 14px;
}

.blackCoin h4,
.commonShadowAndRound .ant-modal-title {
  font-size: 20px !important;
  font-weight: 700;
}

.discountHeart {
  width: 14px;
  border-radius: 0 !important;
}

.mobileNavBottom a {
  color: #2F2F2F !important;
}

.locSpan .anticon {
  color: #fff;
}

.truncateClass span,
.intr-p,
.mobileNavBottom a {
  font-size: 12px !important;
}

.intr-p {
  font-weight: 400 !important;
}

.disabledBtn {
  background: #EBEBE4 !important;
}

.tflTable td,
.tflTable th {
  font-size: 12px;
}

.tflCartHeight {
  height: 100%;
  overflow-y: scroll;
}
.plus.disabledBtn {
  height: 25px;
  width: 30px;
  border-radius: 20px;
}

.giftCardBg {
  background:#316cbb;
  background-repeat: no-repeat;
  color: #fff;
}

.advBg h3 {
  font-size: 18px !important;
  font-weight: 600;
  height: 60px;
}

.advBg {
  background-size: cover !important;
  height: 200px !important;
  width: 100% !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.popupGiftCard .modal-content {
  background: #F0F2F5;
}

.mobileView {
  background: linear-gradient(to bottom, #F8FBFF, white);
}

.advImg {
  height: auto !important;
}
.loginBg{
  background: url("./assets/mobile/sectionBg/resetPassBg.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
.signUpBg{
  background: url("./assets/mobile/sectionBg/signupBg.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
.forgetBg{
  background: url("./assets/mobile/sectionBg/forgot.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
.verifyBg{
  background: url("./assets/mobile/sectionBg/otpBg.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

/* .increBtn.tflCart .minus, */
/* .increBtn.tflCart .plus, */
.plus.disabledBtn {
  height: 25px;
  width: 30px;
  border-radius: 20px;
}

.alertStyle {
  border-radius: 10px !important;
  padding: 2px 10px !important;
}

#cardHistory h4 {
  font-size: 18px !important;
  font-weight: 900 !important;
}

.dropSelect,
.downloadapp {
  background-color: #FEDE30 !important;
  border-color: #FEDE30 !important;
}
.formLeft a:hover{
  color:#fff !important;
}

.privacyLink:hover,
.privacyLink,
a:active,
a:hover,
.activePage,
.downloadapp h2 span,
h2 span,
.downloadapp a,
h2 b {
  color: #0066CC !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
h2 span,
.description,
.subTitle,
.btn-primary,
.css-sghohy-MuiButtonBase-root-MuiButton-root,
.btn-primary:hover {
  border: #0066CC !important;
}

#privacy h4,
.socialIcons .anticon,
.anticon-shopping-cart {
  font-size: 25px !important;
}

p,
.card-text:last-child,
.smallCard .ant-card-body,
#BottomFooter,
.dropSelect,
.blinkText,
#privacy h6 {
  font-size: 16px !important;
}

ul,
.privacyHeading ul {
  overflow-y: hidden !important;
}

.checkoutForm .ant-form-item-label,
.App-header,
.productCol,
.cardList,
.btnWrap,
.buttonContainer,
.productDescription .subTitleTag {
  display: flex !important;
  flex-direction: row !important;
}

.App,
.a-heading,
#cardHistory .tagOrderStatus,
.payment-card .coupon,
.Result-section {
  text-align: center !important;
}

.single-img,
.simple-btn,
.read,
.deliverableCity .ant-tag,
.a-box,
.App-header,
#restaurantDisplay .ant-card-meta,
#html5qr-code-full-region,
#restaurantDisplay .ant-card-body,
.subTitle .redClosedTag,
.flexModal,
.change-btn,
.productContainer,
.subTitle .ant-tag {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.simple-btn,
.read,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.description,
.subTitle .redClosedTag,
.subTitle .ant-tag span,
.dropSelect {
  font-weight: 600 !important;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active {
  background-color: #0066CC;
  text-decoration: underline;
}

.scannerBg,
.payment-card,
.payment-card,
.ButtonContainer {
  height: auto !important;
}

.App-header,
#policies .row,
.productCol,
#restaurantDisplay .ant-card-body,
#html5qr-code-full-region {
  flex-direction: column !important;
}

.deliverableCity .ant-tag,
.simple-btn,
.read,
.ButtonContainer
.list-group-item.active,
#restaurantDisplay .ant-card,
.dropSelect,
.Result-section a,
#html5qr-code-full-regionsss {
  border: none !important;
}


.read,
.simple-btn:hover,
.read:hover,
#action-btn button,
.ButtonContainer,
.list-group-item.active,
.Result-section a,
.btn-primary,
.css-sghohy-MuiButtonBase-root-MuiButton-root,
.btn-primary:hover,
.list-group-item .active,
.CatNameDisplay,
.deliverableCity .ant-tag,
.list-group-item+.list-group-item:hover,
.list-group-item:first-child:hover,
.change-btn,
.ant-checkbox-checked .ant-checkbox-inner,
.simple-btn,
.App-header,
.css-zcbmsk-MuiButtonBase-root-MuiButton-root,
.scannerBg,
.barcodeBg {
  background-color: #0066CC !important;
  border-color: #0066CC !important;
  color: #fff !important;
}

.copytextCursor {
  cursor: pointer;
}

.mobileNavTop,
.subCatList .subCatListUL,
.profileBox,
.logBox,
.col1 ul {
  background-color: #fff !important;
}

#cardHistory .colQuantInOrder p,
#finalCart .ant-row h6:last-child {
  text-align: right !important;
}

.privacyHeading .tableDisplay .ant-pagination.ant-table-pagination.ant-table-pagination-right,
#html5qr-code-full-region__scan_region img,
ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  display: none !important;
}

.navWrapper,
.productCol,
.productContainer,
.buttonContainer,
#restaurantDisplay .ant-card-body,
.flexModal {
  justify-content: space-between !important;
}

.dropSelect,
.mapboxgl-ctrl-geocoder--button,
.list-group-item {
  cursor: pointer;
}

#restaurantDisplay .ant-card-head,
.scannerBg,
.ant-layout-header {
  border-bottom: none;
}

.siteBg {
  height: 100vh !important;
  padding-bottom: 30px !important;
}

.subCatList,
.subCatList .subCatListUL{
  height: 100% !important;
}

.card2 {
  height: auto !important;
}
.bg-gradient {
  background-color: #004AAD;
  background-size: cover;
  width: 100%; 
  height: 100%; 
}

.btnWrap,
#cardHistory .tagOrderStatus {
  width: 50% !important;
}

.orderData {
  max-width: 100% !important;
}

.ant-layout {
  background: transparent !important;
}

.deliverableCity .ant-tag {
  width: 100px !important;
}

.flexModal,
h2 {
  margin-bottom: 15px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  text-decoration: underline !important;
}

.slick-slide .carousel4,
.slick-slide .carousel3,
.slick-slide .carousel2,
.slick-slide .carousel1 {
  height: 200px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: #fff;
}

#lowerImgBlock,
.foodBg,
.slick-slide .carousel4,
.slick-slide .carousel3,
.slick-slide .carousel2,
.slick-slide .carousel1,
.siteBg {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.clickButton {
  margin-bottom: 0px !important;
}

.change-btn,
.payment-card,
#action-btn button,
.productCol,
.profileBox {
  border-radius: 5px !important;
}

h2 {
  display: inline;
}

#downloadApp img {
  width: 130px;
}

.fieldName {
  padding: 0 5px 0 0;
}

.editCartBtn {
  background: #000;
  color: white;
  padding: 2px 15px;
  border-radius: 50px;
}

#tflGiftCard ul li {
  padding: 5px 0;
}

.alertInfo {
  background: #E2F516;
  padding: 15px;
  border-radius: 8px;
}

.modalBox {
  background: #fffbf7;
}

.simple-btn,
.read {
  width: fit-content !important;
  padding: 8px 20px !important;
  letter-spacing: 1px;
  transition: 0.2s ease-in-out;
}

.simple-btn {
  margin: 0 5px 0 0;
}

.read {
  margin-left: 3px;
}

.read:hover,
.simple-btn:hover {
  transform: scale(1.02);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.a-b-img img {
  width: 40px;
  object-fit: contain;
}

.downloadapp {
  justify-content: end;
}

.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.modal-dialog {
  width: 100%;
}

.ant-modal {
  padding-bottom: 0 !important;
}

.ant-modal-mask {
  background-color: rgba(255, 255, 255, 0.9);
}

.ant-modal-content,
.ant-modal-header,
.ant-collapse,
.ant-collapse-content {
  border-bottom: 0;
}

.ant-collapse-content>.ant-collapse-content-box {
  padding: 10px 20px;
}

.ant-checkbox-wrapper {
  align-items: center;
}

#action-btn button {
  border: none !important;
}

.App-header {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
}

.card-text:last-child {
  padding-top: 10px;
  line-height: normal;
}

.blinkText {
  animation: blinker 1.5s linear infinite;
}

#checkout {
  padding-top: 7rem;
}
.checkoutRow .saveBtn span{
font-size: 13px !important;
}

.ButtonContainer {
  position: fixed !important;
  right: 0 !important;
  top: 50% !important;
  outline: none;
  display: block !important;
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  min-width: 5px !important;
  padding: 25px 4px !important;
  text-transform: capitalize !important;
  border-radius: 25px 0px 0px 25px !important;
  position: relative;
  display: inline-block;
}

.textField {
  margin-right: 10px !important;
  margin-top: 20px !important;
}

.radioContainer {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-top: 20px;
}

.restaurantTitle {
  padding: 20px 0px !important;
}

#privacy p {
  font-size: 13px !important;
  margin-top: 0px;
  font-weight: 400;
  line-height: 22px;
}

#privacy .logo img {
  width: 160px;
}

.header h1 {
  font-size: 65px;
}

#privacy h4 {
  font-size: 18px !important;
  letter-spacing: 3px !important;
}

#privacy h6 {
  letter-spacing: 2px !important;
}

.display-5 {
  font-size: 50px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 60px;
}

#privacy p {
  letter-spacing: 1px;
}

.privacy-header {
  margin-top: 50px !important;
}

.privacyHeading {
  font-size: 1.2rem !important;
}

.privacyLink {
  font-weight: bold;
  text-decoration: none;
}

.privacyLink:hover {
  text-decoration-line: underline;
}

.privacyText {
  padding-right: 0 !important;
}

.privacyHeading {
  padding-top: 15px;
}

.privacyHeading ol {
  counter-reset: item;
}

.privacyHeading li {
  display: block;
}

.privacyHeading li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
}

.smallCard .ant-card {
  height: 150px;
}

.smallCard .ant-card-body {
  font-weight: 700;
}

.mobileNavBottom img {
  width: 26px;
  height: 24px;
  margin-bottom: 3px;
}

.mobileNavBottom a {
  font-weight: 500 !important;
  color: #9D9D9D;
}

.bottomThought, .map-row {
  background-color: #f3f3f3;
}

.bottomThought p {
  color: #316cbb;
  font-size: 30px !important;
}

.bottomThought img {
  width: 50px;
}

.mobileNavTop {
  z-index: 99999;
  top: 0;
}

.mobileNavTop .logoSize {
  width: 45px;
}

.subCatList .subCatListUL {
  overflow-y: scroll;
}

.subCatList::-webkit-scrollbar {
  -webkit-appearance: none;
  transition: all 0.9s;
}

.subCatList::-webkit-scrollbar:vertical {
  width: 7px;
}

.subCatList::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.9s;
}

.productCol::-webkit-scrollbar {
  -webkit-appearance: none;
  transition: all 0.9s;
}

.productCol::-webkit-scrollbar:vertical {
  width: 7px;
}

.productCol::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.9s;
}

#payment-status .ant-card-body {
  padding: 0 !important;
}

.productContainer .title {
  font-weight: bold;
  font-size: 18px !important;
}

.subTitle {
  font-weight: 100;
}

.description {
  padding-bottom: 2px;
}

.productContainer {
  padding: 10px 0;
}

.cardList {
  margin: 1rem 0 5rem 0;
  flex-wrap: wrap;
}

.modal-backdrop {
  z-index: 999999;
}

.giftCardModal,
.modal {
  z-index: 9999999;
}

.socialIcons .anticon {
  margin-right: 15px;
}

.salonComingSoon  {
  background: url("./assets/salonbg.webp");
  height: 100%;
  width: 100%;
  background-size: cover;
}
 .htmlAdv .ant-card {
  background: none !important;
  box-shadow: none !important;
}

.pac-container {
  z-index: 100000000;
}

.card.location {
  background-color: #90ee90 !important;
}

.productWrapper {
  padding-top: 81px;
  margin-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 0;
}

.subCategory{
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}

.list-group-item {
  text-transform: capitalize;
  font-size: 1rem !important;
}

.productDes {
  margin-left: 15px;
  margin-bottom: 0;
}

.btnWrap {
  justify-content: flex-end;
}

.productName {
  text-transform: capitalize;
  font-weight: bold !important;
}

.logBox {
  padding: 30px;
}

.profileBox,
.logBox {
  margin: 5px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

#cardHistory .tagOrderStatus {
  font-weight: 500;
  font-style: italic;
}

.historyCard .ant-tag {
  width: fit-content;
  margin: 0
}
.historyCard .ant-card-actions li{
  margin: 0;
}
.buttonContainer {
  padding-top: 20px;
}

#direction-example {
  height: 13rem !important;
}

.custom.danger.invalid-feedback {
  display: block;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.disabledRes {
  cursor: not-allowed;
}

.disabledRes .subTitle {
  color: red;
}

.subTitle .ant-tag {
  width: fit-content;
  border-radius: 40px;
}

.CatNameDisplay,
.ant-modal-title {
  text-transform: capitalize !important;
}

.goBackBtn .orderBtn {
  background-color: "#0066CC";
  padding: "8px 20px";
  border-radius: "20px";
}

.helloBot {
  top: 60px;
  right: 0;
}

.tflCol {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

#restaurantDisplay .ant-card {
  border-radius: 30px !important;
  background-color: #242424;
  height: 350px;
  width: 300px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

#restaurantDisplay .ant-card-head-title {
  color: #fff !important;
  padding: 16px 0 0;
}

.subTitleTag p,
.subTitle .ant-tag span {
  background: #0066CC;
  color: #fff;
  padding: 5px 18px;
  margin-right: 4px;
}

.redClosedTag {
  background: red !important;
  padding: 1px 8px !important;
  width: 120px;
  display: flex;
  align-items: center;
}
.giftCardBox .ant-divider-horizontal{
  width: 65% !important;
  min-width: 65% !important;
  border-top:1px solid #11d187 !important;
}
.ant-divider-horizontal {
  margin: 5px 0px !important;
}

.main {
  position: fixed;
  width: inherit;
  height: 85vh;
}

.CatNameDisplay {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.formStyle .css-dmmspl-MuiFormGroup-root {
  flex-direction: row !important;
  justify-content: space-between !important;
}

.formStyle .css-1nrlq1o-MuiFormControl-root {
  width: 45%;
}

.dropSelect {
  padding: 5px 12px;
}

.payCard button {
  border-radius: 3px;
  margin: 2px;
}

.ant-popover.ant-popover-placement-top {
  z-index: 99999999999 !important;
}

.Result-section a {
  margin-top: 30px;
  padding: 8px 40px;
  font-weight: 500;
  transition: 0.2s ease-in-out;
}

#qr-shaded-region {
  margin-bottom: 20px
}

.css-zcbmsk-MuiButtonBase-root-MuiButton-root {
  border: #2F2F2F;
}

.scroll-to-top {
  position: fixed;
  bottom: 80px;
  right: 20px;
  cursor: pointer;
  background-color: #0066CC;
  color: #fff;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 9999999999;
}

.scroll-to-top:hover {
  background-color: #16EA9E;
}

.mobileNavBottom {
  -webkit-box-shadow: 0px -2px 3px rgba(50, 50, 50, 0.75);
  -moz-box-shadow: 0px -2px 3px rgba(50, 50, 50, 0.75);
  box-shadow: 0px -2px 3px rgba(50, 50, 50, 0.75);
  z-index: 99999;
  background-color: #fff;
}

.agentPage{
  background:url("./assets/underDev.webp");
  background-size: cover;
  height: 100vh ;
  padding: 20px;
}

/*---------------- Media query only ----------------*/
@media only screen and (min-device-width: 320px) and (max-device-width: 1200px) and (orientation: portrait) {
  .comingSoonTextMob{
    font-size: 40px !important;
    font-style: italic;
  }
  .tflTable .ant-table-thead > tr > th, .tflTable .ant-table-tbody > tr > td{
    padding: 5px !important;
  }
  .tflTable td, .tflTable th {
    font-size: 10px;
}
  .orderHistory .ant-tabs-nav-list{
    justify-content: center !important;
  }
  .digitalServicePage .ant-tabs-nav, .orderHistory .ant-tabs-nav {
    position: fixed;
    top: 5px;
    right: 0;
    left: 0;
    background: none !important;
  }
  #tflMarketRow {
    margin-top: 80px;
}
  .mission {
    padding: 10px !important;
  }
  .salonComingSoon {
      background: url("./assets/mobile/barberbg2.webp");
      height: 100%;
      width: 100%;
      background-size: cover;
  }
  
.agentPage{
  background:url("./assets/mobile/live-time-bg.webp");
  background-size: cover;
  height: 100vh ;
  padding: 20px;
}

  .site-layout-background .p-5,
  .site-layout-background .productContainer,
  .main,
  #BottomFooter .px-5.pt-5.pb-3.justify-content-between.row,
  #BottomFooter .justify-content-between.pt-5.pb-0.px-5.row,
  .agent,
  .main .list-group-item,
  .CollapseCategoryMobile .ant-collapse-content-box,
  .subCat,
  #card3,
  .about,
  .subCatListUL,
  .locBtn .locSpan,
  .ant-layout-header {
    padding: 0 !important;
  }

  .privacy-header {
    margin-top: 0 !important;
  }

  .col1 {
    display: none !important;
  }

   #online-header {
    height: 100vh;
    overflow-y: scroll;
  } 

  .siteBg {
    overflow: hidden scroll;
    height: 92vh !important;
  background: url("./assets/mobileBg.webp");
  background-size: cover;
  }

  .cardContainer {
    width: 100vw !important;
  }

  .image-container {
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .overlay-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .overlay-text {
    position: absolute;
    top: 18%;
    width: 100%;
    text-align: left;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #2F2F2F;
    padding: 0 0 0 30px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 6px;
  }

  .skeltonTopMargin {
    margin-top: 10px;
  }

  .mobileProfile {
    -webkit-box-shadow: 0px 1px 6px rgba(50, 50, 50, 0.75);
    ;
    -moz-box-shadow: 0px 1px 6px rgba(50, 50, 50, 0.75);
    ;
    box-shadow: 0px 1px 6px rgba(50, 50, 50, 0.75);
    ;
  }

  .productDescription,
  .main,
  .change-btn,
  .intro {
    display: flex;
  }

  .videoSlide .slick-slide,
  .videoSlide .videoTag {
    height: 250px !important;
    border-radius: 15px;
    background: #000;
  }

  .orderData,
  .about,
  .b-container,
  .productDescription,
  .main,
  .privacyText{
    flex-direction: column !important;
  }



  .cardList {
    margin: 0 !important;
  }

  .cardList .ant-card-body {
    justify-content: start !important;
    padding: 10px 15px;
  }

  .subTitleTag p,
  .ezyCoins-ul li, .giftCardFormBg  .form-control, .giftCardBg select#exampleForm\.ControlInput1 {
    font-size: 12px !important;
  }

  .search-location {
    justify-content: space-between;
  }

  .ezyCoins-h3 h3 {
    font-weight: 700 !important;
    font-size: 16px !important;
  }

  .welcome-text {
    font-size: 30px !important;
    color: #004AAD;
  }

  #tflMarketRow .ant-tabs-nav {
    background: none !important;
  }

  #tflMarketRow .ant-tabs-nav-list {
    justify-content: space-around !important;
  }

  .ant-tabs-nav {
    z-index: 999 !important;
    background: #fff !important;
  }

  .smallCard .smallCard1,
  .smallCard .smallCard2,
  .smallCard .smallCard3,
  .smallCard .smallCard4,
  .smallCard .smallCard5,
  .smallCard .smallCard6 {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 155px;
    width: 126px;
  }

  .advImg img {
    width: 120px !important;
    height: 120px !important;
  }

  #tflOrderHistory p,
  #tflOrderHistory span,
  #tflOrderHistory .ant-steps-item-title {
    font-size: 12px !important;
  }

  #tflOrderHistory span {
    font-weight: 600;
  }

  #tflOrderHistory .ant-table-thead>tr>th,
  #tflOrderHistory .ant-table-tbody>tr>td {
    padding: 5px;
    font-size: 12px;
  }

  .ant-modal-wrap {
    top: -90px !important;
  }
  .digitalServicePage .ant-tabs-tab-btn, .orderHistory .ant-tabs-tab-btn{
    color: #fff !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1200px) and (orientation: portrait) {
  .cardBg-brown,
  .cardBg-green {
    height: 150px;
  }

  .cardBg-green img,
  .cardBg-brown img {
    width: 50px;
  }

  .foodBg {
    background: none;
  }

  .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
    font-size: 12px;
  }

  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  ul {
    color: #fff !important;
  }

  .subCatList .subCatListUL,
  body {
    overflow: hidden !important;
  }

  .subTitleTag p,
  .subTitle .ant-tag span,
  .ant-tag span,
  .subTitle .redClosedTag {
    font-size: 11px !important;
    padding: 2px 20px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 600;
    text-decoration: underline;
  }

  .pointsTable button,
  .pointsTable .ant-table-thead>tr>th,
  .pointsTable .ant-table-tbody>tr>td {
    font-size: 12px !important;
  }


  .searchagain-img .ant-empty-description span {
    font-size: 20px !important;
  }

  .pointsDisplay h5 {
    font-size: 16px;
    font-weight: 900;
  }

  .pointsTable .ant-table-tbody>tr>td {
    padding: 5px;
  }

  .productDes {
    text-align: left;
  }

  .pointsTable .ant-table-tbody>tr>td,
  .cardBg-green p,
  .cardBg-brown p {
    text-align: center;
  }

  .ant-tabs-nav-list {
    width: 100vw !important;
    flex-wrap: wrap;
  }

  .ant-tabs-tab-btn {
    text-wrap: balance;
  }


  .ant-tag span,
  .subTitle .redClosedTag {
    width: 60px;
  }

  .mobileStyle a {
    padding: 0 20px;
    width: 300px;
    height: 80px;
    border: 3px solid #13CD52;
    margin: 0 0 15px;
    background-color: #2F2F2F;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-weight: 600;
    text-align: left;
    font-size: 18px;
  }

  .mobileStyle img {
    margin-right: 15px;
  }

  .CatNameDisplay,
  .col1 {
    display: none;
  }

  .CollapseCategoryMobile {
    display: block;
  }

  .cardLink {
    padding-top: 25px;
  }

  body h1,
  body h1 span {
    text-align: center;
    font-size: 25px !important;
  }

  .ant-tabs-card.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab {
    text-wrap: wrap;
  }

  .ant-tabs-left>.ant-tabs-content-holder {
    margin-left: 100px;
  }

  .main {
    position: absolute !important;
  }

  .productCol img {
    width: 190px !important;
  }

  .onlineheader{
    width: 150px;
    margin-top: 50px;
  }

  .card-title {
    font-size: 15px !important;
  }

  body .cardImage {
    width: 150px !important;
  }
  .card-text:last-child {
    font-size: 14px !important;
  }

  .card2 {
    padding: 15px !important;
    margin-bottom: 20px;
  }

  .btnLink1 {
    margin-top: 15px !important;
  }

  .searchagain-img .ant-empty-image,
  .card2,
  .searchagain-img .ant-empty-image,
  .card2,
  #product-list,
  .subCatList {
    height: auto !important;
  }

  .searchagain-img .ant-empty-image img {
    height: 200px;
  }

  .other-nav .navWrapper {
    color: #fff !important;
    display: flex;
    align-items: center;
    margin: 0 10px;
  }

  .other-nav .searchLocation .locBtn,
  .searchLocation {
    width: auto !important;
  }

  .site-card-wrapper img {
    width: 100% !important;
  }

  .productCol {
    border: 1px solid #525252 !important;
  }

  .title,
  .description {
    font-size: 12px !important;
  }

  .productDescription {
    justify-content: space-between;
    align-items: start;
    flex-direction: column !important;
  }

  .productWrapper.container {
    padding-left: 10px !important;
  }

  .site-layout-background .description {
    flex-direction: row !important;
  }

  .col1 .list-group {
    height: 20vh !important;
    display: block;
    overflow-y: scroll;
  }

  .main .list-group-item {
    display: inline-block;
    border-radius: 5px !important;
  }

  .ant-collapse>.ant-collapse-item>.ant-collapse-header {
    border: 1px solid #DADADA;
    border-radius: 5px !important;
  }

  .change-btn {
    align-items: center;
  }

  .intro {
    text-align: center;
    align-items: center !important;
    justify-content: center;
  }

  .ant-modal-title,
  h1,
  h2,
  h3,
  h4,
  h6,
  p,
  .ant-modal-close-x {
    font-size: 13px !important;
  }

  #checkout {
    padding-top: 1rem !important;
  }

  .minus,
  .plus {
    padding: 3px 7px !important;
  }

  .cardList {
    justify-content: space-between !important;
  }

  .list-group-item {
    text-align: center;
  }

  .display-5 {
    font-size: 35px;
  }

  .inputWrapper {
    display: block !important;
  }

  .css-1wnsr1i {
    max-height: 100vh;
    overflow: scroll;
    padding: 15px !important;
  }

  .textField {
    width: 280px !important;
  }

  .subCatListUL,
  .ant-collapse-content-box div {
    min-height: auto !important;
    max-height: none !important;
  }

  .cardImage {
    width: 150px !important;
  }

  .locBtn .locSpan {
    font-size: 14px !important;
  }
}

@media only screen and (device-width: 820px) and (device-height: 1180px) and (orientation:portrait) {
  .main {
    flex-direction: row !important;
    height: 90vh !important;
  }

  .subCat.p-3.col-lg-3.col {
    margin-top: 20px !important;
  }

  .siteBg .main .list-group-item {
    padding: 10px !important;
  }

  .CatNameDisplay,
  .col1 {
    display: block !important;
  }

  h1,
  h2 {
    font-size: 20px !important;
  }

  .fullWidthTab,
  .colPadding {
    max-width: 100vw !important;
  }

  .site-layout-background .cardList {
    padding-top: 90px !important;
    justify-content: flex-start !important;
  }

  .handImg {
    display: none !important;
  }

  .colPadding {
    padding: 40px !important;
  }

  .coinPotali {
    width: 100%;
  }
}

@media only screen and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {

  .productCol {
    width: auto !important;
  }

  .productCol,
  .subCat {
    width: 35% !important;
  }

  .main {
    flex-direction: row !important;
    height: 90vh !important;
  }

  .subCat.p-3.col-lg-3.col {
    margin-top: 20px !important;
  }

  .siteBg .main .list-group-item {
    padding: 10px !important;
  }

  .CatNameDisplay,
  .col1 {
    display: block !important;
  }

  h1,
  h2 {
    font-size: 20px !important;
  }

  #tflGiftCard img.helloBot {
    width: 100px !important;
  }

  .site-layout-background .cardList {
    padding-top: 90px !important;
    justify-content: flex-start !important;
  }

  .handImg {
    display: none !important;
  }

  .colPadding {
    min-width: 100vw !important;
    padding: 40px !important;
  }

  .coinPotali {
    width: 100%;
  }
}
@media only screen and (min-device-width:1100px) and (max-device-width:1200px) {
  .agentWeb{
    margin-top: 100px;
  }
}
@media only screen and (min-device-width:1024px) and (max-device-width:1100px) {

  #downloadApp img,
  #restaurantDisplay .ant-card-body img {
    width: 100px;
  }

  .downloadapp {
    height: auto !important;
  }

  #restaurantDisplay .ant-card-body h4 {
    font-size: 18px;
  }

  #restaurantDisplay .ant-card-head-title img {
    width: 30px;
  }

  #restaurantDisplay .ant-card {
    height: 230px;
    width: 200px;
  }

  #restaurantDisplay .ant-card-body img {
    width: 150px;
  }

  #restaurantDisplay .ant-card-body h4 {
    font-size: 18px;
  }

  #restaurantDisplay .ant-card-head-title img {
    width: 30px;
  }

  #restaurantDisplay .ant-card {
    height: 300px;
    width: 220px;
  }

  nav {
    background: #fff;
    justify-content: center;
    height: 107px;
    padding: 0px 30px;
    position: fixed;
  }

  .display-5 {
    font-size: 35px;
  }

  .privacyText .ant-table {
    overflow: scroll;
  }

  .mission {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .privacyText {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-device-width: 500px) and (max-device-width: 1500px) {
  .colPadding {
    padding: 30px 0;
  }

  .padding-40-180 {
    padding: 50px 80px;
  }

  h1,
  h2,
  h2 span {
    font-size: 24px !important;
  }

  .orderData h4 {
    font-size: 16px !important;
    font-weight: 600;
  }

  #checkout {
    padding-top: 3rem;
  }

  #restaurantDisplay .ant-card-body img,
  .ant-empty-image {
    width: 150px;
  }

  .aboutNavLink,
  .aboutNavLink,
  .card-title,
  .simple-btn,
  .read {
    font-size: 14px !important;
  }

  #restaurantDisplay .ant-card-body h4 {
    font-size: 18px;
  }

  #restaurantDisplay .ant-card-head-title img {
    width: 30px;
  }

  #restaurantDisplay .ant-card {
    height: 300px;
    width: 220px;
  }

  .card2 a img {
    width: 100px !important;
  }

  .navbar {
    height: 64px;
  }

  .a-b-img img {
    width: 35px;
  }

  .simple-btn,
  .read {
    padding: 5px 15px !important;
  }

  body .cardImage {
    width: 350px !important;
  }

  .card2 {
    height: 350px !important;
    justify-content: space-between !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .productCol span {
    font-size: 12px;
  }

  .list-group-item {
    padding: 10px;
  }

  .productContainer {
    padding: 10px 0 0 !important;
  }

  .redClosedTag svg {
    width: 10px;
  }

  .list-group-item {
    font-size: 12px !important;
  }

  .CatNameDisplay {
    padding: 10px 15px !important;
  }

  .addBtn {
    max-height: 40px;
  }
}

@media only screen and (min-device-width:1366px) and (max-device-width:1919px) {
  #restaurantDisplay .ant-card-body img {
    width: 150px;
  }

  #restaurantDisplay .ant-card-body h4 {
    font-size: 18px;
  }

  #restaurantDisplay .ant-card-head-title img {
    width: 30px;
  }

  #restaurantDisplay .ant-card {
    height: 300px;
    width: 220px;
  }

  img.cardImage {
    width: 515px !important;
  }

  .card2 a img {
    width: 120px !important;
  }

  .card2 {
    height: 420px !important;
    padding: 20px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }

  h1 span,
  h1 {
    font-size: 40px !important;
  }

  .card-text:last-child {
    font-size: 12px !important;
  }
}

@media only screen and (min-device-width:500px) and (max-device-width: 2560px) {
  .mission.profilePages {
    background: url("./assets/commonBg.webp");
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    }
}
@media only screen and (min-device-width: 1200px) and (max-device-width: 1360px) {
  .nav_bg .card-body .cardLink {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .nav_bg .cardLink2 {
    margin-top: 5px;
  }
}

@media only screen and (min-device-width: 2560px) {

  p,
  .aboutNavLink,
  span,
  i,
  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td,
  b,
  .privacyText,
  #BottomFooter a,
  h2,
  h2 span {
    font-size: 22px !important;
  }

  h6 {
    font-size: 1.5rem
  }

  .storeImg img,
  #downloadApp img {
    width: 300px;
  }

  .simple-btn,
  .read {
    width: 300px;
    font-size: 20px;
    border-radius: 100px;
  }

  .privacyHeading {
    padding-top: 40px;
  }

  #restaurantDisplay .ant-card {
    width: 500px !important;
  }

  .privacyHeading {
    font-size: 2.2rem !important;
  }

  .a-b-img img {
    width: 50px;
  }
}